
import { computed, defineComponent, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { Field } from "vee-validate";
import { ElNotification } from "element-plus";
import { hideModal } from "@/core/helpers/dom";
import Vue from 'vue'; 


export default defineComponent({
    name: "EditCredentialModal",
    components: {
        Field
    },
    props: ["currConsumerData", "id"],
    data() {
        return {
            // currConsumer:{} as any,
            errors: [],
            configurationId: "",
            consumer: [],
            manufacturer: [],
            apiProvider: [],
            useMCPTData: [],
            currentIndex: 0,
            tabs: [
                { title: 'Form View'},
                { title: 'JSON View '},
            ],
            form_data_name: "",
            form_data_value:"",
            showPopup: false,
            field_name: '',
            input_name: '',
            input_value: '',
            previousValues: {},
            useServiceData: [] as any,
            useOrganizationType: [] as any,
            api_list: [{"apiEndpointUrl":"http://no_url.com", "name": "11"}, {"apiEndpointUrl": "http://dummy.com", "id": "22"}],
            apiGroupList: [],
            useGroupData: [] as any,
            productList: [] as any,
            manufacturerDetails: {} as any,
            categoryList: {} as any,
        }
    },
    setup(props, { emit }) {
        const onBoardConsumerModalRef = ref<null | HTMLElement>(null);
        // props.currConsumerData.category = props.currConsumerData.category ? props.currConsumerData.category : ""
        const currConsumer = computed(() => props.currConsumerData)
        const currConsumerId = computed(() => props.id)
        return {
            currConsumer,
            onBoardConsumerModalRef,
            currConsumerId
        }
    }, 
    methods: {
        validateForm() {
            this.errors.splice(0, this.errors.length);
            if (!this.currConsumer.category || this.currConsumer.category === '') {
                this.errors.push("Please select category");
            }else{
                if(this.currConsumer.category && !this.categoryList.includes(this.currConsumer.category)){
                    this.errors.push("Please enter a valid category");
                }
            }
            if (this.currConsumer.credentialOrgType === '') {
                this.errors.push("Please select credential org Type");
            }else{
                if (!["P", "C", "M", "T", "p", "c", "m", "t"].includes(this.currConsumer.credentialOrgType)){
                    this.errors.push("Please enter a valid credential org Type");
                }
            }
            if (this.currConsumer.credentialOrgType === "C" || this.currConsumer.credentialOrgType === "c") {
                if(this.currConsumer.consumerCode === ''){
                    this.errors.push("Please Select consumer code");
                }
            }
            // if(this.currConsumer.consumerCode && this.currConsumer.consumerCode != ''){
            //     const consumer_ids_list = this.consumer.map(item => item);
            //     if (!consumer_ids_list.includes(this.currConsumer.consumerCode)){
            //         this.errors.push("Please enter a valid consumer code");
            //     }
            // }
            // if(this.currConsumer.manufacturerCode && this.currConsumer.manufacturerCode != ''){
            //     const consumer_ids_list = this.manufacturer.map(item => item);
            //     if (!consumer_ids_list.includes(this.currConsumer.manufacturerCode)){
            //         this.errors.push("Please enter a valid manufacturer code");
            //     }
            // }
            // if(this.currConsumer.apiProviderOrg && this.currConsumer.apiProviderOrg != ''){
            //     const api_provider_ids_list = this.apiProvider.map(item => item);
            //     if (!api_provider_ids_list.includes(this.currConsumer.apiProviderOrg)){
            //         this.errors.push("Please enter a valid api provider code");
            //     }
            // }
            if(this.currConsumer.productGroupId && this.currConsumer.productGroupId != ''){
                const product_group_ids_list = this.useGroupData.map(item => item.id);
                // console.log("product_group_ids_list-->>", product_group_ids_list, " and this.formData.productGroupId->", this.formData.productGroupId);
                if (!product_group_ids_list.includes(this.currConsumer.productGroupId)){
                    this.errors.push("Please enter a valid product group");
                }
            }
            // if(this.currConsumer.productId && this.currConsumer.productId != ''){
            //     // console.log("this.productList-->>",this.productList);
            //     if (!this.productList){
            //         this.errors.push("Please enter a valid product");
            //     }else{
            //         if (!this.productList.includes(this.currConsumer.productId)){
            //             this.errors.push("Please enter a valid product");
            //         }
            //     }
                
            // }
            if (this.currConsumer.category === "" ) {
                if (this.currConsumer.manufacturerCode === ''){
                    this.errors.push("Please Select category code");
                }
            }
            if (this.currConsumer.credentialOrgType === "M" || this.currConsumer.credentialOrgType === "m") {
                if (this.currConsumer.manufacturerCode === ''){
                    this.errors.push("Please Select manufacturer code");
                }
            }
            if (this.currConsumer.authenticationType === "tokenAuth" && this.currConsumer.tokenEndpoint.url === '') {
                this.errors.push("Please add token end point url code")
            }
            // else{
            //     if(this.tokenEndpoint){
            //         const apis_ids_list = this.api_list.map(item => item.apiEndpointUrl);
            //         if (!apis_ids_list.includes(this.tokenEndpoint.url)){
            //             this.errors.push("Please enter valid tokenend point url")
            //         }  
            //     }
            // } 
            if (this.currConsumer.authenticationType === "tokenAuth" && this.currConsumer.tokenEndpoint.verbId === '') {
                this.errors.push("Please add api method for Token Endpoint");
            }
            if (this.currConsumer.tokenValidity === "") {
                this.errors.push("Please add token validity");
            }
            if (this.currConsumer.serviceName === '') {
                this.errors.push("Please add service name");
            }
            // if (this.currConsumer.authenticationType === '') {
            //     this.errors.push("Please add authentication type");
            // }
            //console.log("this.showErrorApi-->>", this.showErrorApi);
            //console.log("this.tokenEndpoint.url-->>", this.tokenEndpoint.url);
            // if (this.showErrorApi) {
            //     this.errors.push("Please enter valid api endpoint url")
            // }
            // if (this.showTokenError) {
            //     this.errors.push("Please enter valid token endpoint url")
            // }
            // //console.log("this.apiEndpoint.url-->>", this.apiEndpoint);
            // if(this.apiEndpoint && this.apiEndpoint.url===""){
            //     this.errors.push("Please enter valid api endpoint url")
            // }else{
            //     if(this.apiEndpoint){
            //         const apis_ids_list = this.api_list.map(item => item.apiEndpointUrl);
            //         if (!apis_ids_list.includes(this.apiEndpoint.url)){
            //             this.errors.push("Please enter valid api endpoint url")
            //         }  
            //     }
            // } 
        }, 
        async fetchManufacturerDetails(manufacturerCode) {
            this.handleOrganizationChange(manufacturerCode)
            ApiService.get(`/product?manufacturerId=${manufacturerCode}`)
                .then((data) => {
                    // , ...data.data.data.WealthManagement
                    this.manufacturerDetails = { ...data.data.data.Insurance }
                    // console.log("fetch manufacrturer products after -->>", this.manufacturerDetails, "--with code", manufacturerCode);
                })
                .catch((error) => {
                    console.error("Error while fetching products for manufacturer", error)
                })
        },
        udpateManufacturerProducts(manufacturerCode, productGroupId){
            ApiService.get(`/product?manufacturerId=${manufacturerCode}`)
                .then((data) => {
                    // , ...data.data.data.WealthManagement
                    this.manufacturerDetails = { ...data.data.data.Insurance }
                    // console.log("fetch manufacrturer products after -->>", this.manufacturerDetails, "--with code", manufacturerCode);
                    this.setProductList(productGroupId)
                })
                .catch((error) => {
                    console.error("Error while fetching products for manufacturer", error)
                })
        },
        handleOrganizationChange(organizationCode) {
            // console.log("organization code", organizationCode);
            ApiService.get(`/organizations/${organizationCode}/apis?featureMode=latest_endpoints`)
            .then((data) => {
                // console.log("organiztion data.data.data.api_list-->>", data.data.data.api_list);
                this.api_list = this.api_list.concat(data.data.data.api_list)
                // console.log("updated api_list", this.api_list);
            })
            .catch((error) => {
                console.error("Errro while on change fetching organizatin apis", error);
            });
        },
        setProductList(productGroupId) {
            
            const ProductGroup = this.useGroupData.find((item)=>item.id === productGroupId)
            // console.log("in setProductList ProductGroup-->>", ProductGroup, "--this.manufacturerDetails-->>", this.manufacturerDetails);
            this.productList = this.manufacturerDetails[ProductGroup.name];

        },
        getMasterData() {
            ApiService.get("/master")
                .then((data) => {

                    this.useServiceData = (JSON.parse(JSON.stringify(data.data.data.serviceName)))
                    this.useOrganizationType = (JSON.parse(JSON.stringify(data.data.data.organizationType)))
                    this.categoryList = (JSON.parse(JSON.stringify(data.data.data.categoryList)))
                })
                .catch((error) => {
                    console.error("Error", error)
                })
        },
        getMasterData2() {
        ApiService.get("/master?organizationId=1SB")
            .then((data) => {
                const { APIGroup } = data.data.data
                this.apiGroupList = APIGroup;

            })
            .catch((error) => {
                console.error("Error", error)
            })
        },
        addCredentialField(item, item1){
            const temp = { ...this.currConsumer };
            temp[item][item1][""] = "";
            this.currConsumer = temp;
        },
        onChangeCredentialFieldKey(item, item1, oldKey, newKey){
            // console.log("item-->>", item, "--item1--<>>", item1, "--item2-->", oldKey, "--value-->>", newKey);
            let pre_val = this.currConsumer[item][item1][oldKey];
            const temp = { ...this.currConsumer };
            temp[item][item1][newKey] = pre_val;
            delete temp[item][item1][oldKey];
            this.currConsumer = temp;
            // this.currConsumer[item][item1][newKey] = pre_val;
            // delete this.currConsumer[item][item1][oldKey];
            // this.$forceUpdate();

            // console.log("after changes this.currConsumer-->>", this.currConsumer)
        },
        closePopup() {
            this.input_name = ""
            this.input_value = ""
            this.showPopup = false
        },
        addField(field){
            // console.log("add data clicked..", this.field_name, "--", this.input_name, "--", this.input_value);
            if (this.field_name in this.currConsumer.credentials){
                this.currConsumer.credentials[this.field_name][this.input_name] = this.input_value;
            }
            this.input_name = ""
            this.input_value = ""
            this.showPopup = false;
        },
        convert_object_to_array(credetial_field) {
            //console.log("here with value", credetial_field)
            let arr = [];
            for (let key in credetial_field){
                arr.push({"name": key,  "value": credetial_field[key]})
            }
            return arr;
        },
        onInput(event) {
            if (event.target.textContent) {
                const content = JSON.parse(event.target.textContent|| '{}');
                // console.log("content -->>", content);
                // console.log("this.currConsumer -->>", this.currConsumer);
                this.currConsumer.manufacturerCode = content.manufacturerCode || "";
                // this.currConsumer.credentialOrgCode = content.credentialOrgCode || "";
                this.currConsumer.credentialOrgType = content.credentialOrgType || "";
                this.currConsumer.consumerCode = content.consumerCode || "";
                this.currConsumer.serviceName = content.serviceName || "";
                this.currConsumer.productId = content.productId || "";
                this.currConsumer.category = content.category || "";
                this.currConsumer.productGroupId = content.productGroupId || "";
                this.currConsumer.credentials = content.credentials || {};
                this.currConsumer.version = content.version || "";
                this.currConsumer.operationId = content.operationId || "";
                this.currConsumer.authenticationType = content.authenticationType || "";
                if ('apiEndpoint' in content){
                    this.currConsumer.apiEndpoint = content.apiEndpoint;
                }
                if ('tokenEndpoint' in content){
                    this.currConsumer.tokenEndpoint = content.tokenEndpoint;
                }
                // console.log("this.currConsumer2 -->>", this.currConsumer);
            }
        },
        getCombinedData() {
            // console.log("this.currConsumer-->>", this.currConsumer);
            return JSON.stringify(this.currConsumer, null, 2);
        },
        handleClick(index) {
            // console.log("index --,<", index, "- --", event);
            this.currentIndex = index; // Prevent the default form submit behavior
          
          
        },
        submitForm() {
            this.validateForm();
            const { authenticationType, apiEndpoint, credentials, tokenEndpoint, tokenValidity, version, operationId, serviceName, manufacturerCode, consumerCode, credentialOrgType, apiProviderOrg, productGroupId, productId, category} = this.currConsumer;
            const newPayload = { authenticationType, apiEndpoint, credentials, tokenEndpoint, tokenValidity, version, operationId, serviceName, manufacturerCode, consumerCode, credentialOrgType, apiProviderOrg, productGroupId, productId, category} as any;
            if (!authenticationType || authenticationType === ""){
                newPayload['authenticationType'] = "noAuth"
            }
            newPayload['credentialOrgCode'] = newPayload['consumerCode']
            newPayload['manufacturerId'] = newPayload['manufacturerCode']
            if (this.errors.length === 0){
                ApiService.put(`/cms/configuration/${this.currConsumerId}`, newPayload)
                .then((data) => {
                    this.$emit("credentialsChange");
                    hideModal(this.onBoardConsumerModalRef);
                    ElNotification({
                        title: 'Success',
                        message: 'Updated Credential!',
                        type: 'success',
                        duration: 2000,
                        position: 'top-right'
                    })


                })
                .catch((error) => {
                    console.error(error)
                    ElNotification({
                        title: 'Error',
                        message: JSON.stringify(error.response.data['errors'][0]['errorMessage']),
                        type: 'error',
                        duration: 2000,
                        position: 'top-right'
                    })
                });
            }
            

        },

        //get MCPT Data
        getMCPT() {

            ApiService.get(`/organization?organizationTypes=C`)
                .then((data) => {
                    this.useMCPTData = (JSON.parse(JSON.stringify(data.data.data)));
                    this.consumer = this.useMCPTData.map((item) => item.id);
                })
                .catch((error) => {
                    console.error("Error in MCPT", error)
                })
            ApiService.get(`/organization?organizationTypes=M`)
                .then((data) => {
                    
                    this.useMCPTData = (JSON.parse(JSON.stringify(data.data.data)));
                    // this.apiProvider = JSON.parse(JSON.stringify(this.useMCPTData));
                    this.manufacturer = this.useMCPTData.map((item) => item.id);
                })
                .catch((error) => {
                    console.error("Error in MCPT", error)
                })
            ApiService.get(`/organization?organizationTypes=M,C,P,T`)
                .then((data) => {
                    this.useMCPTData = (JSON.parse(JSON.stringify(data.data.data)));
                    this.apiProvider = this.useMCPTData.map((item) => item.id);
                })
                .catch((error) => {
                    console.error("Error in MCPT", error)
                })
        },
        getGroup() {
            ApiService.get("/api/group")
                .then((data) => {
                    this.useGroupData = (JSON.parse(JSON.stringify(data.data.data)));
                })
                .catch((error) => {

                    console.error("Error in GroupApi", error)
                })
        },
    },
    created() {
        this.getMCPT();
        // created() {
        this.getMasterData2();
        this.getGroup();
        this.getMasterData();
        // this.getMCPT();
    // }
    },
    async updated(){
        
        // console.log("this.currConsumer in mounted..-->>", this.currConsumer);
        if ("manufacturerCode" in this.currConsumer){
            this.udpateManufacturerProducts(this.currConsumer['manufacturerCode'], this.currConsumer["productGroupId"]);
        }
    }
})
