
import { defineComponent, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { hideModal } from "@/core/helpers/dom";
import { ElNotification } from "element-plus";


export default defineComponent({

  name: "filter-credentials-modal",

  data() {

    return {
      manufacturer: [] as any,
      consumer: [] as any,
      useMCPTData: [] as any,
      useGroupData: [] as any,
      useServiceData: [] as any,
      useOrganizationType: [] as any,
      apiProvider: [] as any
    }

  },
  setup() {
    const filterCredentialModalRef = ref<null | HTMLElement>(null);
    const formData = ref({
      manufacturerCode: "",
      credentialOrgType: "",
      consumerCode: "",
      apiProviderOrg: "",
      serviceName: "",
      productGroupId: "",
    });

    return {
      formData,
      filterCredentialModalRef
    }
  },

  mounted() {
    this.getMCPT();
    this.getMasterData();
    this.getGroup();
  },
  methods: {

    //remote method search
    remoteMethod(query: string) {
      const test = query;
    },
    //Reset Form
    resetForm(formData) {

      this.formData.manufacturerCode = "",
        this.formData.credentialOrgType = "",
        this.formData.consumerCode = "",
        this.formData.apiProviderOrg = "",
        this.formData.serviceName = "",
        this.formData.productGroupId = ""
    },

    //Apply Filters
    applyFilter(formData) {
      this.$emit("setFilter", this.formData)
      hideModal(this.filterCredentialModalRef)
    },

    //get MCPT Data
    getMCPT() {

      ApiService.get(`/organization?organizationTypes=M,C,P,T`)
        .then((data) => {

          // console.log("MCPT Data", data.data.data);
          this.useMCPTData = (JSON.parse(JSON.stringify(data.data.data)))
          this.manufacturer = this.useMCPTData.filter((item) => item.organizationType === "Manufacturer")
          this.apiProvider = this.useMCPTData.filter((item) => item.organizationType != "Consumer");
          this.consumer = this.useMCPTData.filter((item) => item.organizationType === "Consumer");

        })
        .catch((error) => {
          console.log("Error in MCPT", error)
        })
    },

    //getMasterData
    getMasterData() {
      ApiService.get("/master")
        .then((data) => {
          // console.log("Master Data", data.data.data)

          this.useServiceData = (JSON.parse(JSON.stringify(data.data.data.serviceName)))
          this.useOrganizationType = (JSON.parse(JSON.stringify(data.data.data.organizationType)))
        })
        .catch((error) => {
          console.log("Error", error)
        })
    },

    //Get GroupData
    getGroup() {
      ApiService.get("/api/group")
        .then((data) => {
          // console.log("Group Data", data.data.data)
          this.useGroupData = (JSON.parse(JSON.stringify(data.data.data)));
        })
        .catch((error) => {

          console.log("Error in GroupApi", error)
        })
    },
  }
})
