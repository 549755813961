
import { computed, defineComponent, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { Field } from "vee-validate";
import { ElNotification } from "element-plus";
import { hideModal } from "@/core/helpers/dom";


export default defineComponent({
    name: "OnBoardConsumer",
    components: {
        Field
    },
    props: ["currConsumerData"],
    data() {
        return {
            configurationId: "",
            consumer: [],
            useMCPTData: [],
            visibel: false

        }
    },
    setup(props) {
        const onBoardConsumerModalRef = ref<null | HTMLElement>(null);
        const currConsumer = computed(() => props.currConsumerData)
        //     const formData = ref({
        // manufacturerCode: "Test",
        // productGroupId: "",
        // productId: "",
        // credentialOrgType: "",
        // consumerCode: "",
        // authenticationType: "",
        // });
        return {
            // formData
            currConsumer,
            onBoardConsumerModalRef
        }
    },

    methods: {



        //remotemethod
        remoteMethod(query: string) {
            console.log(query)
        },

        submitForm() {
            ApiService.post("/cms/configuration", this.currConsumer)
                .then((data) => {


                    hideModal(this.onBoardConsumerModalRef);
                    this.$emit("credentialsChange");
                    ElNotification({
                        title: 'Success',
                        message: 'New Credential added!',
                        type: 'success',
                        duration: 2000,
                        position: 'top-right'
                    })


                })
                .catch((error) => {
                    console.log(error)
                    ElNotification({
                        title: 'Error',
                        message: JSON.stringify(error.response.data['errors'][0]['errorMessage']),
                        type: 'error',
                        duration: 2000,
                        position: 'top-right'
                    })
                });

        },
        //get MCPT Data
        getMCPT() {

            ApiService.get(`/organization?organizationTypes=C`)
                .then((data) => {

                    this.useMCPTData = (JSON.parse(JSON.stringify(data.data.data)));
                    this.consumer = this.useMCPTData.map((item) => item.id);

                })
                .catch((error) => {
                    console.log("Error in MCPT", error)
                })
        },
    },
    created() {
        this.getMCPT();
    },
})
