
import { defineComponent, onMounted, ref, watch, defineAsyncComponent } from "vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { ElNotification, ElLoading } from "element-plus";
import FilterCredentials from "@/components/modals/forms/FilterCredentials.vue";
import onBoardManufacturer from "@/components/modals/forms/onBoardManufacturer.vue";
import OnBoardConsumer from "@/components/modals/forms/OnBoardConsumer.vue";
import Datatable from "@/components/kt-datatable/CredentialDataTabel.vue";
import DeleteCredentialModal from "@/components/modals/forms/DeleteCredentialModal.vue";
import EditCredentialModal from "@/components/modals/forms/EditCredentialModal.vue";
import ApiService from "@/core/services/ApiService";

interface ICredentials {
  id: number;
  manufacturerCode: string;
  consumerCode: string;
  credentialOrgType: string;
  apiProviderOrg: string;
  serviceName: string;
  version: string;
  productId: string;
  productGroupId: string;
  operationId: string;
}

export default defineComponent({
  name: "ConfigureCredentials",
  components: {
    FilterCredentials,
    onBoardManufacturer,
    Datatable,
    DeleteCredentialModal,
    EditCredentialModal,
    OnBoardConsumer
  },
  data() {
    return {
      userData: [],
      page: 1,
      count: 25,
      currConsumer: {} as any,
      id: 0,
      totalCount: 0,
      search: "",
      searchFlag: true,
      initCust: [],
      filterCred: {}
    };


  },
  methods: {

    //EditCredential
    editCredentialMethod(configurationId) {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.id = configurationId;
      ApiService.get(`/cms/configuration/${configurationId}`)
        .then((data) => {
          loading.close();
          let curr_configuration = JSON.parse(JSON.stringify(data.data.data));
          curr_configuration.category = curr_configuration.category ? curr_configuration.category : ""
          this.currConsumer = {...curr_configuration}

          if (this.currConsumer.id)
            delete this.currConsumer.id
        })
        .catch((error) => {
          loading.close();
          ElNotification({
            title: 'Error',
            message: JSON.stringify(error.response.data['errors'][0]['errorMessage']),
            type: 'error',
            duration: 2000,
            position: 'top-right'
          })
          console.log("Error in onBoard Data", error)
        })

    },

    //onBoard consumer
    onBoardConsumerMethod(configurationId) {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      ApiService.get(`/cms/configuration/${configurationId}`)
        .then((data) => {
          this.currConsumer = JSON.parse(JSON.stringify(data.data.data));
          loading.close();
          if (this.currConsumer.id)
            delete this.currConsumer.id
        })
        .catch((error) => {
          loading.close();
          ElNotification({
            title: 'Error',
            message: JSON.stringify(error.response.data['errors'][0]['errorMessage']),
            type: 'error',
            duration: 2000,
            position: 'top-right'
          })
          console.log("Error in onBoard Data", error)
        })

    
    },

    //delete credentials
    enablefunction(Id, Status) {

      (this.$refs["deleteCredentialForm"] as any).callUpdate(

        Id,
        Status
      );
    },

    //setCountPage And call GetCredentials
    setCountPage(data) {
      const { count, page } = data;
     
        this.getCredentials(Number(page), Number(count))
      

    },

    //Change Track Get Credential After Update
    ChangeCredList() {
      this.getCredentials(this.page, this.count);
    },

    //Get Credentials
    getCredentials(page, count) {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      ApiService.get(`/cms/configuration?pageNumber=${page}&pageSize=${count}&manufacturerCode=${this.filterData.manufacturerCode}&consumerCode=${this.filterData.consumerCode}&apiProviderOrg=${this.filterData.apiProviderOrg}&productGroupId=${this.filterData.productGroupId}&serviceName=${this.filterData.serviceName}&organizationType=${this.filterData.credentialOrgType}`)
        .then((data) => {
          const { cmsList, totalCount } = data.data.data
          loading.close();
          this.totalCount = totalCount;
          if (this.totalCount === 0) {
            ElNotification({
              title: 'Warning',
              message: "No record Found",
              type: 'warning',
              duration: 2000,
              position: 'top-right'
            })
          }

          this.setinitCred(cmsList);
        })
        .catch((error) => {
             loading.close();
             //console.log("Error in Get Credential", error)
          ElNotification({
            title: 'Error',
            message: JSON.stringify(error.response.data['errors'][0]['errorMessage']),
            type: 'error',
            duration: 2000,
            position: 'top-right'
          })
         
          
        })
    },
    //search Item
    searchItems() {
      this.userData.splice(
        0,
        this.userData.length,
        ...this.initCust
      );
      
      if (this.search !== "") {
        let results = []
        for (let j = 0; j < this.userData.length; j++) {
          if (this.searchingFunc(this.userData[j], this.search)) {
            results.push(this.userData[j]);
          }

        }
        this.userData.splice(
          0,
          this.userData.length,
          ...results
        );
        if (this.userData.length == 0 && this.searchFlag == true) {
          this.searchFlag = false
          ElNotification({
            title: 'Warning',
            message: 'No Records Found!',
            type: 'warning',
            duration: 2000,
            position: 'top-right'
          })
        }
        else if (this.userData.length != 0) {
          this.searchFlag = true
        }



      }
    },
    //Searching Function
    searchingFunc(obj, value) {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object") && !(typeof obj[key] === "boolean")) {
          if (obj[key] != "") {
            if (obj[key].toLowerCase().indexOf(value.toLowerCase()) != -1) {
              return true;
            }
          }

        }
      }
      return false;
    },
    //setCredential
    async setinitCred(cred) {
      this.userData = await JSON.parse(JSON.stringify(cred));
      // this.totalCount=totalCount
    
      this.initCust.length=0;
      this.initCust.splice(
        0,
        this.userData.length,
        ...this.userData
      );
      this.search = "";
     
    },
    //set filter
    setFilter(filterCred) {
      this.filterData.manufacturerCode = filterCred.manufacturerCode,
        this.filterData.credentialOrgType = filterCred.credentialOrgType
      this.filterData.consumerCode = filterCred.consumerCode,
        this.filterData.apiProviderOrg = filterCred.apiProviderOrg,
        this.filterData.serviceName = filterCred.serviceName,
        this.filterData.productGroupId = filterCred.productGroupId
      this.getCredentials(this.page, this.count);
    }
  },
  //Hook Used to call Api 
  created() {

    this.getCredentials(this.page, this.count);
  },
  setup() {
    const filterData = ref({
      manufacturerCode: "",
      credentialOrgType: "",
      consumerCode: "",
      apiProviderOrg: "",
      serviceName: "",
      productGroupId: "",
    });

    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Credentials Configuration", ["Manage"]);
    });

    const tableHeader = ref([
      {
        name: "Credential Id",
        key: "id",
        sortable: true,
      },

      {
        name: "consumer code",
        key: "consumerCode",
        sortable: true,
      },
      {
        name: "manufacturer code",
        key: "manufacturerCode",
        sortable: true,
      },
      {
        name: "credentialOrg Type",
        key: "credentialOrgType",
        sortable: true,
      },
      {
        name: "API ProviderOrg",
        key: "apiProviderOrg",
        sortable: true,
      },
      {
        name: "Service Name",
        key: "serviceName",
        sortable: true,
      },
      {
        name: "Product Group",
        key: "productGroupId",
        sortable: true,
      },
      {
        name: "Product ID",
        key: "productId",
        sortable: true,
      },
      {
        name: "operation Id",
        key: "operationId",
        sortable: true,
      },
      {
        name: "Version",
        key: "version",
        sortable: true,
      },
      {
        name: " Status",
        key: "active",
        sortable: true,
      },

      {
        name: "Edit Action",
        key: "actions",
      },
      {
        name: "On Board Consumer",
        key: "OnBoardConsumer",
      },
    ]);

    return {
      filterData,
      tableHeader

    };
  },
});
